import request from '@/utils/request'

const userApi = {
  outputList: '/api/output_style',// 型体产量
  machineList:'/api/machine_output',// 机器产量
  timesCountList:'/api/output_by_time',// 时间段产量

}

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */


// 顶部总产量
// export function reqIndexTopCount (parameter) {
//   return request({
//     url: userApi.machineList,
//     method: 'post',
//     data: parameter
//   })
// }

 // 机器产量
export function reqMachineList (parameter) {
  return request({
    url: userApi.machineList,
    method: 'post',
    data: parameter
  })
}


// 型体产量
export function reqOutputList (parameter) {
  return request({
    url: userApi.outputList,
    method: 'post',
    data: parameter
  })
}

// 时间段产量
export function reqOutputByTimes (parameter) {
  return request({
    url: userApi.timesCountList,
    method: 'post',
    data: parameter
  })
}

