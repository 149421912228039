<template>
  <div class="top-date-box">
    <div class="totla">设备数 Qty of machine : <span style="color:#fd870a">{{totalDeviceCount}}</span></div>
    <div class="times-box">{{nowDate}} {{nowTime}} {{nowWeek}}</div>
  </div>
</template>

<script>
export default {
  props:{
    deviceNum: {
      // type: Number||String,
      default: ""
    },
  },
  data() {
    return {
      totalDeviceCount:0,
      nowDate: this.$store.state.nowDate, // 当前日期
      nowTime: this.$store.state.nowTime, // 当前时间
      nowWeek: this.$store.state.nowWeek // 当前星期
    };
  },
  mounted(){
    setInterval(this.getDate, 500)
  },
  watch: {
    deviceNum: {
      handler () {
        this.totalDeviceCount=this.deviceNum
      },
      deep: true
    }
  },
  methods: {
    getDate() {
      var _this = this
      let yy = new Date().getFullYear()
      let mm = new Date().getMonth() + 1
      let dd = new Date().getDate()
      let week = new Date().getDay()
      let hh = new Date().getHours()
      let ms = new Date().getSeconds() < 10 ? "0" + new Date().getSeconds() : new Date().getSeconds()
      let mf = new Date().getMinutes() < 10 ? "0" + new Date().getMinutes() : new Date().getMinutes()
      /*if (week == 1) {                                                                                              
        this.nowWeek = "星期一"
      } else if (week == 2) {
        this.nowWeek = "星期二"
      } else if (week == 3) {
        this.nowWeek = "星期三"
      } else if (week == 4) {
        this.nowWeek = "星期四"
      } else if (week == 5) {
        this.nowWeek = "星期五"
      } else if (week == 6) {
        this.nowWeek = "星期六"
      } else {
        this.nowWeek = "星期日"
      }*/
      _this.nowTime = this.fillZero(hh) + " : " + mf + " : " + ms 
      // _this.nowTime = this.fillZero(hh) + "时" + mf + "分" + ms + "秒"
      _this.nowDate = this.fillZero(yy) + " - " + this.fillZero(mm) + " - " + this.fillZero(dd) 
      //_this.nowDate = this.fillZero(yy) + "年" + this.fillZero(mm) + "月" + this.fillZero(dd) + "日"
      this.$store.commit("setNowDate", _this.nowDate );
      this.$store.commit("setNowTime", _this.nowTime);
      this.$store.commit("setNowWeek",_this.nowWeek);
    },
    // 给时间补零
    fillZero(str) {
      var realNum
      if (str < 10) {
        realNum = "0" + str
      } else {
        realNum = str
      }
      return realNum
    }
  }
};
</script>

<style lang="scss" scoped>
.top-date-box{
  width:1000px;
  display:flex;
  //background-color: aqua;//显示
  justify-content: space-between;
  align-items: center;
  font-size:19px;
  font-weight: bold;
  height:50px;
  padding-right:70px;
  margin-left: 15px;
}
</style>