<template>
  <div class="a-top-box">
    <a-form layout="inline">
      <div class="time-box">
        <div class="ymd-box">
          <div
            class="item"
            :class="item.id==dateType?'cur-date':''"
            v-for="(item,index) in searchList"
            :key="index"
            @click="handleChangeDate(item)"
          >{{item.name}}</div>
        </div>
            
        <a-form-item label>
          <a-config-provider :locale="locale">
            <!-- YYYY-MM-DD HH:mm:ss -->
            <a-range-picker
              :value="createValue"
              format="YYYY-MM-DD"
              style="width: 100%"
              @change="onSearchChange"
              :ranges="ranges"
            />
            <!-- @calendarChange="calendarChange" -->
          </a-config-provider>
        </a-form-item>

        <div class="language-switch">
          <button @click="switchToEnglish">EN</button>
          <button @click="switchToChinese">中文</button>
        </div>
      </div>
    </a-form>
  </div>
</template>

<script>
import enUS from "ant-design-vue/lib/locale-provider/en_US";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import moment from "moment";
export default {
  data() {
    return {
      //locale: enUS,
      locale: zhCN,
      // createValue: [],
      ranges: {
        "一月内 Last Month": () => [
          moment()
            .startOf("day")
            .subtract(1, "month"),
          moment()
        ],
        "昨天 Yesterday": () => [
          moment()
            .startOf("day")
            .subtract(1, "days"),
          moment()
            .endOf("day")
            .subtract(1, "days")
        ],
        "最近一周 Last week": () => [
          moment()
            .startOf("day")
            .subtract(1, "weeks"),
          moment()
        ]
      },
      searchList: [
        {
          name: "日 Day",
          id: 0
        },
        {
          name: "周 Week",
          id: 1
        },
        {
          name: "月 Month",
          id: 2
        }
      ]
    };
  },
  computed:{
    dateType(){
      return this.$store.state.searchType
    },
    start_time(){
      return this.$store.state.startTimes
    },
    end_time(){
       return this.$store.state.endTimes
    },
    createValue(){
      return [this.$store.state.startTimes,this.$store.state.endTimes]
    }
  },
  methods: {
    handleChangeDate(row) {
      this.$store.commit("setStartTimes", "");
      this.$store.commit("setEndTimes", "");
      this.$store.commit("setSearchType",row.id);

      let queryParam={
        start_time:"",
        end_time:"",
        dateType:row.id
      }
      this.$emit("onsearch",queryParam)
      
    },
    onSearchChange(date, dateString) {
      console.log(date)
      let _this=this;
      this.$store.commit("setStartTimes",dateString[0]);
      this.$store.commit("setEndTimes", dateString[1]);
      this.$store.commit("setSearchType",3);

      let queryParam={
        start_time: dateString[0],
        end_time: dateString[1],
        dateType:3
      }
      _this.$nextTick(() => {
        _this.$emit("onsearch",queryParam)
      })
    },
    switchToEnglish() {
      this.locale = enUS;
     },
     switchToChinese() {
      this.locale = zhCN;
     }
  }
};
</script>

<style lang="scss" scoped>
.a-top-box {
  width: 100%;
  height: 50px;
  //background: #e84949;//ffffff
  .time-box {
    height: 50px;
    display: flex;
    //background-color: rgb(61, 61, 228);
    align-items: center;
    padding-left: 33px;
  }
  .ymd-box {
    width: 270px;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 4px;
    //background: #d02a2a;
    //margin: 0 0 0 20px;
    margin-right: 8px; /* 调整日周月与日期选择器之间的间距 */
    .item {
      width: 90px;
      height: 24px;
      line-height: 24px;
      font-size: 14px;
      color: #808792;
      text-align: center;
      border-radius: 3px;
      cursor: pointer;
      margin-right: 10px; /* 调整选项之间的间距 */
    }
    .cur-date {
      color: #ffffff;
      background: #fd870a;
    }
  }
  .language-switch {
    display: flex;
    align-items: center;
    margin-right: -40px; /* 调整切换按钮与设备数之间的间距 */
  }
  .language-switch button {
    margin-right: 5px; /* 调整按钮之间的间距 */
    //background-color: #ffffff;
    border: 1px solid transparent; /* 设置透明边框 */
    background-color: transparent; /* 背景透明 */
    color: #808792; /* 文字颜色 */
    cursor: pointer;
  }
  .language-switch button:hover {
    border-color: #1890ff; /* 鼠标悬停时显示边框 */
    border-radius: 3px;
  }
}
</style>
